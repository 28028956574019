/* global $ */
import 'script-loader!pickadate/lib/compressed/picker';
import 'script-loader!pickadate/lib/compressed/picker.date';
import '../../config/pickadate.es';

const { monthsShort } = $.fn.pickadate.defaults;

export default function stringToDate (string) {
  if (!string) return null;

  const today = new Date();
  const [date, monthShort, yearShort] = string.split('-');
  const month = monthsShort.indexOf(monthShort.toLowerCase());
  const year = Number(
    `${today
      .getFullYear()
      .toString()
      .slice(0, 2)}${yearShort}`,
  );

  return new Date(year, month, date);
}
