const Loading = () => {
  return (
    <li style={{ textAlign: 'center' }}>
      <div className="autocomplete-item">
        <div className="loader">
          <div />
          <div />
          <div />
        </div>
      </div>
    </li>
  );
};

export default Loading;
