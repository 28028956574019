import { createContext, Fragment } from 'preact';
import { useEffect, useRef } from 'preact/compat';
import { useState } from 'preact/hooks';
import { camelizeKeys } from 'humps';
import { selectIcon } from '../templates/place';
import titleize from '../utils/titleize';
import createUrl from '../utils/createUrl';
import normalize from '../utils/normalize';
import Loading from './Loading';

require('../styles/AutocompleteSelect.min.css');

const SelectContext = createContext();

const AutocompleteSelect = ({
  field,
  error,
  hasArrow,
  placeholder,
  sourceUrl,
  displayType,
  isPackagesSearch,
  onChange,
  from,
}) => {
  const [input, setInput] = useState('');
  const [places, setPlaces] = useState([]);
  const [options, setOptions] = useState([]);
  const [optionsLoaded, setOptionsLoaded] = useState(false);
  const [showItems, setShowItems] = useState(false);
  const [originsLoaded, setOriginsLoaded] = useState(false);
  const [disabledInput, setDisabledInput] = useState(false);

  const containerRef = useRef(null);
  const inputRef = useRef(null);
  const optionsRef = useRef(null);

  const handleClickOutside = event => {
    if (containerRef && !containerRef.current.contains(event.target)) {
      setShowItems(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const placesUrl = () => {
    const url = createUrl(sourceUrl);

    if (from) url.setQueryParam('from', from);
    else url.setQueryParam('prefetch', 'true');

    return url.href;
  };

  const fetchTerminals = () => {
    setOptionsLoaded(false);
    setShowItems(true);

    fetch(placesUrl(from))
      .then(response => response.json())
      .then(data => {
        setOptionsLoaded(true);
        setOriginsLoaded(true);
        setOptions(data);
        setPlaces(data);
      })
      .then(() => {
        optionsRef.current.scrollTo(null, 0);
        inputRef.current.focus();
      });
  };

  useEffect(() => {
    if (field === 'destination') {
      if (!from) {
        setInput('');
        onChange(field, {});
        setDisabledInput(true);
      } else {
        onChange(field, {});
        setInput('');
        setDisabledInput(false);
        fetchTerminals();
      }
    }
  }, [from]);

  const getPlaceDisplayText = place => {
    let displayText = '';

    if (displayType === 'city') {
      const cityDisplay = isPackagesSearch ? '' : `, ${place.display}`;
      displayText = `${place.city_name}${cityDisplay}`;
    } else {
      displayText = `${place.display}, ${place.state}`;
    }

    return displayText;
  };

  const filterOptions = value => {
    setOptions(places.filter(place => normalize(getPlaceDisplayText(place)).includes(value)));
  };

  const handleInputChange = event => {
    const newInput = normalize(event.target.value);
    setInput(newInput);
    filterOptions(newInput);

    if (newInput === '') {
      onChange(field, {});
    }
  };

  const handleElementSelect = value => {
    setInput(value);
    setShowItems(false);
  };

  const handleInputClick = () => {
    if ((field === 'origin' || field === 'place') && !originsLoaded) {
      fetchTerminals();
    }

    if (originsLoaded && !showItems) {
      setShowItems(true);
    }
  };

  return (
    <SelectContext.Provider
      value={{
        displayType,
        isPackagesSearch,
        onChange,
        field,
        handleElementSelect,
      }}
    >
      <div
        className={`${field}-wrapper ${error ? 'error' : ''} ${hasArrow ? 'has-arrow' : ''}`}
        key={`${field}-wrapper`}
        ref={containerRef}
        role="presentation"
      >
        <input
          type="text"
          autoComplete="off"
          className={`${field} es-input`}
          placeholder={`${placeholder}`}
          disabled={disabledInput}
          onClick={() => handleInputClick()}
          onInput={handleInputChange}
          value={input}
          ref={inputRef}
        />
        <SelectContainer
          options={options}
          showItems={showItems}
          optionsLoaded={optionsLoaded}
          optionsRef={optionsRef}
        />
      </div>
    </SelectContext.Provider>
  );
};

const SelectContainer = ({ options, showItems, optionsLoaded, optionsRef }) => {
  return (
    <ul
      className="es-list"
      ref={optionsRef}
      style={{
        display: showItems ? 'block' : 'none',
        top: '49px',
        left: '0px',
        width: '294px',
        paddingLeft: '0px',
        marginTop: '0px',
        overflowY: 'scroll',
      }}
    >
      {!optionsLoaded && <Loading />}
      {options.length > 0 &&
        showItems &&
        options.map(option => {
          return <SelectElement place={option} />;
        })}
    </ul>
  );
};

const SelectElement = ({ place }) => {
  const [hover, setHover] = useState(false);

  const formatCityDisplay = isPackagesSearch => {
    const cityDisplay = isPackagesSearch ? '' : `, ${titleize(place.display)}`;
    return `${titleize(place.city_name)}${cityDisplay}`;
  };

  const handleElementClick = (field, onChange, handleElementSelect) => {
    onChange(field, camelizeKeys(place));
    handleElementSelect(titleize(`${place.display}, ${place.state}`));
  };

  return (
    <SelectContext.Consumer>
      {value => {
        return (
          <li
            className={`es-visible ${hover ? 'selected' : ''}`}
            style="display: flex;"
            onClick={() =>
              handleElementClick(value.field, value.onChange, value.handleElementSelect)
            }
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
          >
            {value.displayType === 'city' ? (
              formatCityDisplay(value.isPackagesSearch)
            ) : (
              <Fragment>
                <div
                  className="autocomplete-icon"
                  dangerouslySetInnerHTML={{
                    __html: selectIcon(place.result_type, false),
                  }}
                />
                <div className="place-info">
                  <b>{`${titleize(place.display)}, `}</b>
                  {titleize(place.state)}
                </div>
              </Fragment>
            )}
          </li>
        );
      }}
    </SelectContext.Consumer>
  );
};

export default AutocompleteSelect;
