import encodePassengers from './encodePassengers';
import createUrl from './createUrl';
import serializeOccupancy from './serializeOccupancy';
import stringToDate from './stringToDate';

function resultsPath(searchParams, dataAttributes) {
  const { origin, destination, departureDate, returnDate, passengers } = searchParams;
  const { slug: originSlug } = origin;
  const { slug: destinationSlug } = destination;
  const { line, transporter } = dataAttributes;
  const encodedPassengers = encodePassengers(passengers);
  const path = ['/search', originSlug, destinationSlug, departureDate];

  if (returnDate) {
    path.push(returnDate);
  }

  path.push('p', encodedPassengers);

  if (line && !transporter) {
    path.push('departures', line);
  } else {
    path.push('providers');
  }

  return `//${dataAttributes.funnelUrl}${path.join('/')}`;
}

function formatDateForHotels(stringDate) {
  const parsedDate = stringToDate(stringDate);

  const addsZero = number => (number < 10 ? `0${number}` : number);

  return `${parsedDate.getFullYear()}-${addsZero(parsedDate.getMonth() + 1)}-${addsZero(
    parsedDate.getDate()
  )}`;
}

function serializeQueryParams({ utmParams, transporter, useUtmFor }, searchType) {
  const utmTargets = useUtmFor.split(',').map(target => target.trim());

  if (!utmTargets.includes(searchType)) return '';

  const params = {
    transporter,
    utm_campaign: utmParams.campaign,
    utm_medium: utmParams.medium,
    utm_source: utmParams.source,
    utm_content: utmParams.content,
  };

  const queryParams = Object.entries(params)
    .filter(([, value]) => value)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`);

  return (queryParams.length ? '?' : '') + queryParams.join('&');
}

function removeSearchParams(url) {
  return createUrl(url).removeQueryParams(
    'origin',
    'destination',
    'departureDate',
    'returnDate',
    'passengers'
  ).queryString;
}

function makeOpenTicketUrl({ origin, destination }) {
  return `/search/open-ticket/${origin.slug}/${destination.slug}`;
}

function makePackagesUrl({ place }, config) {
  const { packagesUrl } = config;
  return `//${packagesUrl}/paquetes-hacia/${place.slug}`;
}

function makeHotelsUrl(fields, config) {
  const { place, startDate, endDate, guests } = fields;
  const { hotelsUrl } = config;
  const serializedOccupancy = serializeOccupancy(guests);
  const serializedStartDate = formatDateForHotels(startDate);
  const serializedEndDate = formatDateForHotels(endDate);

  // TODO: use hotelsResultsType to redirect to list or map
  return `//${hotelsUrl}/hotel-results?place=${place.slug}&startDate=${serializedStartDate}&endDate=${serializedEndDate}&occupancy=${serializedOccupancy}`;
}

function selectSearchTypePath(searchType, fields, config) {
  switch (searchType) {
    case 'bus':
      return resultsPath(fields, config);
    case 'packages':
      return makePackagesUrl(fields, config);
    case 'hotel':
      return makeHotelsUrl(fields, config);
    default:
      return '';
  }
}

export default function resultsUrl(fields, config, searchType) {
  const { tripType } = fields;

  if (tripType === 'openTicket') {
    const path = makeOpenTicketUrl(fields);
    return `//${config.funnelUrl}${path}`;
  }

  const path = selectSearchTypePath(searchType, fields, config);
  const query =
    config.forwardQuery === 'true'
      ? removeSearchParams(window.location.href)
      : serializeQueryParams(config, searchType);

  return `${path}${query}`;
}
