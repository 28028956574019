/* global $ */
import { Component } from 'preact';
import Datepicker from 'async!./datepicker';
import PassengersDropdown from 'async!./passengersDropdown';
import BannerBranding from 'async!./BannerBranding';
import RoundSwitch from 'async!./RoundSwitch';
import validate from '../utils/validate';
import resultsUrl from '../utils/resultsUrl';
import decodePassengers from '../utils/decodePassengers';
import searchFromQuery from '../utils/searchFromQuery';
import validateDate from '../utils/validateDate';
import stringToDate from '../utils/stringToDate';
import createUrl from '../utils/createUrl';
import gtmSetDataLayer from '../utils/gtmSetDataLayer';
import recentTripListener from '../utils/recentTripListener';
import WidgetTitle from './WidgetTitle';
import '../style.scss';

export default class Search extends Component {
  state = {
    errors: {},
    passengers: {},
    tripType: 'oneWay',
    recentTrips: [],
  };

  fieldClasses = {
    origin: 'input.origin',
    destination: 'input.destination',
    departureDate: 'input.departureDate',
    returnDate: 'input.returnDate',
  };

  constructor(props) {
    super(props);

    this.onChangeAutocomplete = this.onChangeAutocomplete.bind(this);
    this.onChangePassengers = this.onChangePassengers.bind(this);
    this.onChangeDepartureDate = this.onChangeDepartureDate.bind(this);
    this.onChangeReturnDate = this.onChangeReturnDate.bind(this);
    this.receiveRecentTrips = this.receiveRecentTrips.bind(this);
    this.setGtmProperties = this.setGtmProperties.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.disposeListener = recentTripListener(this.receiveRecentTrips);
  }

  componentDidMount() {
    this.setInitialState();
    const { config } = this.props;
    const { optInReturn } = config;

    if (optInReturn) {
      const { tripType } = this.state;

      if (tripType === 'oneWay') {
        this.$get('returnDate').hide();
        this.setState({ returnDate: null });
      }
    }
  }

  componentDidUpdate(_, { tripType: prevTripType }) {
    const { config } = this.props;
    const { optInReturn } = config;
    const { tripType } = this.state;

    if (!optInReturn || tripType === prevTripType) return;

    if (tripType === 'round') {
      this.$get('returnDate').show();
    } else if (tripType === 'oneWay') {
      this.$get('returnDate').hide();
    }
  }

  componentWillUnmount() {
    this.disposeListener();
  }

  onChangeAutocomplete(field, place) {
    const { origin, destination } = place;
    const currentState = this.state;
    const { errors } = currentState;

    if (origin && destination) {
      this.setState({ origin, destination });
    } else {
      this.setState({ [field]: place });

      if (errors[field] && Object.keys(place).length > 0) {
        this.setState(prevState => ({
          errors: {
            ...prevState.errors,
            [field]: '',
          },
        }));
      }
    }

    this.setGtmProperties();
  }

  onChangeDepartureDate(departureDate) {
    this.setState({ departureDate });

    if (departureDate !== '') {
      this.$get('returnDate').trigger('changeDepartureDate', departureDate);
    }

    const { errors } = this.state;
    if (errors.departureDate && departureDate !== '') {
      this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          departureDate: '',
        },
      }));
    }

    this.setGtmProperties();
  }

  onChangeReturnDate(returnDate) {
    this.setState({ returnDate });

    if (returnDate !== '') {
      this.$get('departureDate').trigger('changeReturnDate', returnDate);
    }

    const { errors } = this.state;
    if (errors.returnDate && returnDate !== '') {
      this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          returnDate: '',
        },
      }));
    }

    this.setGtmProperties();
  }

  onChangePassengers(type, quantity) {
    const { passengers: currentPassengers } = this.state;
    const passengers = {
      ...currentPassengers,
      [type]: quantity,
    };

    this.setState({ passengers });
    this.setGtmProperties();
  }

  onSubmit() {
    if (!this.validate()) return;

    const { config } = this.props;
    const url = resultsUrl(this.state, config, 'bus');
    const { openNewWindow } = config;

    if (openNewWindow) {
      window.open(url);
    } else {
      window.top.location.href = url;
    }
  }

  setGtmProperties() {
    const { config } = this.props;
    const { origin = {}, destination = {}, departureDate, returnDate, passengers } = this.state;

    if (config.useGtm) {
      gtmSetDataLayer({
        originCity: origin.cityName,
        originState: origin.state,
        originCountry: origin.country,
        destinationCity: destination.cityName,
        destinationState: destination.state,
        destinationCountry: destination.country,
        startDate: stringToDate(departureDate),
        endDate: stringToDate(returnDate),
        travelers: passengers,
      });
    }
  }

  receiveRecentTrips(recentTrips) {
    this.setState({ recentTrips });
  }

  setInitialState() {
    const { config } = this.props;
    const {
      maxDaysSearch,
      accentColor,
      primaryColor,
      passengers,
      departureDate,
      returnDate,
      destination,
      origin,
    } = config;
    const {
      departureDate: qDepartureDate,
      destination: qDestination,
      origin: qOrigin,
      passengers: qPassengers,
      returnDate: qReturnDate,
    } = searchFromQuery();

    const initialDeparture = qDepartureDate || departureDate;
    const initialDestination = { slug: qDestination || destination };
    const initialOrigin = { slug: qOrigin || origin };
    const initialPassengers = qPassengers || passengers;
    const initialReturn = qReturnDate || returnDate;

    this.setState({
      departureDate: validateDate(initialDeparture, maxDaysSearch) ? initialDeparture : null,
      destination: initialDestination,
      origin: initialOrigin,
      passengers: decodePassengers(initialPassengers),
      returnDate: validateDate(initialReturn, maxDaysSearch) ? initialReturn : null,
    });

    // Clicktripz initial data
    this.setGtmProperties();

    if (departureDate) {
      this.$get('returnDate').trigger('changeDepartureDate', departureDate);
    }

    if (returnDate) {
      this.$get('departureDate').trigger('changeReturnDate', returnDate);
    }

    this.rootEl.style.setProperty('--accent-500', accentColor);
    this.rootEl.style.setProperty('--primary-700', primaryColor);
  }

  validate() {
    const currentState = this.state;
    const errors = validate(currentState);
    this.setState({ errors });

    console.log(errors);

    return Object.keys(errors).length === 0;
  }

  $get(fieldName) {
    return $(this.rootEl).find(this.fieldClasses[fieldName]);
  }

  render(props, state) {
    const { config } = this.props;
    const {
      origin,
      tripType,
      errors,
      passengers,
      departureDate,
      returnDate,
      destination,
      recentTrips,
    } = state;

    const {
      Autocomplete,
      sourceUrl,
      maxDaysSearch,
      passengersDropdown,
      optInReturn,
      showOpenTicket,
      brandingCopy,
      widgetTitle,
      line,
      airline,
      transporter,
      showErrors,
      displayType,
    } = config;

    const hideReturn = optInReturn && tripType === 'oneWay';
    const protocol = /https?:/.test(window.location.protocol) ? 'https:' : 'http:';
    const autocompleteUrl = createUrl(`${protocol}//${sourceUrl}`);

    if (line) {
      autocompleteUrl.setQueryParam('line', line);
    } else if (airline) {
      autocompleteUrl.setQueryParam('airline', airline);
    } else if (transporter) {
      autocompleteUrl.setQueryParam('transporter', transporter);
    }

    return (
      <form ref={el => (this.rootEl = el)} onSubmit={e => e.preventDefault()}>
        {brandingCopy && <BannerBranding copy={brandingCopy} />}

        <div className="title-wrapper">
          <RoundSwitch
            value={tripType}
            onChange={newTripType => this.setState({ tripType: newTripType })}
            showOpenTicket={showOpenTicket}
            optInReturn={optInReturn}
          />
          {widgetTitle && <WidgetTitle copy={widgetTitle} />}
        </div>

        <div className="search-form">
          <div className="form-field">
            <Autocomplete
              field="origin"
              sourceUrl={autocompleteUrl.href}
              onChange={this.onChangeAutocomplete}
              to={destination && destination.slug}
              initialPlaceSlug={origin && origin.slug}
              place={origin}
              error={Boolean(errors.origin)}
              placeholder="Origen"
              recentTrips={recentTrips}
              displayType={displayType}
            />
            {showErrors && <span className="fieldError">{errors.origin}</span>}
          </div>

          <div className="form-field">
            <Autocomplete
              field="destination"
              sourceUrl={autocompleteUrl.href}
              onChange={this.onChangeAutocomplete}
              from={origin && origin.slug}
              initialPlaceSlug={destination && destination.slug}
              place={destination}
              error={Boolean(errors.destination)}
              placeholder="Destino"
              displayType={displayType}
            />
            {showErrors && <span className="fieldError">{errors.destination}</span>}
          </div>

          <div className={`form-field-date-wrap ${tripType === 'openTicket' ? 'hide' : ''}`}>
            <div className={`form-field-date ${hideReturn ? 'border-radius' : ''}`}>
              <Datepicker
                field="departureDate"
                onClose={this.onChangeDepartureDate}
                error={Boolean(errors.departureDate)}
                placeholder="Salida"
                maxDaysSearch={maxDaysSearch}
                date={departureDate}
              />
              {showErrors && <span className="fieldError">{errors.departureDate}</span>}
            </div>

            <div className={`form-field-date ${hideReturn ? 'hide' : ''}`}>
              <Datepicker
                field="returnDate"
                onClose={this.onChangeReturnDate}
                error={Boolean(errors.returnDate)}
                hide={hideReturn}
                placeholder={`Regreso ${!optInReturn ? '(Op.)' : ''}`}
                maxDaysSearch={maxDaysSearch}
                date={returnDate}
              />
              {showErrors && <span className="fieldError">{errors.returnDate}</span>}
            </div>
          </div>

          {passengersDropdown && (
            <div className="form-field dropdown">
              <PassengersDropdown
                onUpdatePassengers={this.onChangePassengers}
                error={Boolean(errors.passengers)}
                passengers={passengers}
              />
              {showErrors && <span className="fieldError">{errors.passengers}</span>}
            </div>
          )}

          <div className="button-wrapper">
            <button
              type="submit"
              onClick={this.onSubmit}
              className="search-button"
              ct-submit="true" // Clicktripz
            >
              Buscar
            </button>
          </div>
        </div>
      </form>
    );
  }
}
