import addDate from './addDate';
import stringToDate from './stringToDate';

export default function validateDate (stringDate, maxDaysSearch) {
  // date in dd-mmm-yy format
  if (!/[0-9]{2}-[a-z]{3}-[0-9]{2}/i.test(stringDate)) return false;

  const dateToValidate = stringToDate(stringDate);
  const now = new Date();
  const maxDate = addDate(new Date(), maxDaysSearch);

  if (dateToValidate.getTime() < now.getTime()) return false;

  if (dateToValidate.getTime() > maxDate.getTime()) return false;

  return true;
}
