/* global $ */
import { Component } from 'preact';
import AutocompleteTypeahead from 'async!./AutocompleteTypeahead';
import AutocompleteSelect from 'async!./AutocompleteSelect';
import { initTest, getTest, emitTest } from '../utils/abtest';
import createUrl from '../utils/createUrl';
import Tabs from './Tabs';
import Tab from './Tab';
import HotelSearch from './HotelSearch';
import Search from './search';
import PackagesSearch from './PackagesSearch';

class SearchHub extends Component {
  abtest = 'a';

  state = {
    activeTab: 0,
  };

  constructor(props) {
    super(props);

    this.setConfig();
    this.createTabsInfo = this.createTabsInfo.bind(this);
    this.initTest();
  }

  setConfig() {
    const {
      airline,
      transporter,
      autocompleteType = 'typeahead',
      accentColor = null,
      brandingCopy = null,
      funnelUrl = 'viaje.reservamos.mx',
      line,
      maxDaysSearch = '30',
      optInReturn = 'true',
      openNewWindow = 'false',
      passengersDropdown = 'true',
      primaryColor = null,
      showErrors = 'false',
      useGtm = 'false',
      utmCampaign,
      utmMedium,
      utmSource,
      utmContent,
      widgetLayout = '',
      widgetTitle = '',
      forwardQuery = 'false',
      useUtmFor = 'bus',
      showOpenTicket = 'false',
      sourceUrl = 'www.reservamos.mx/api/v2/places',
      searchTypes = 'bus',
      hotelsUrl = 'travel.resertravel.com',
      packagesUrl = 'paquetes.resertravel.com',
      hotelsResultsType = 'list',
      displayType = 'city',
      departureDate,
      destination,
      origin,
      passengers,
      returnDate,
    } = this.props;

    const hexColorRegex = /^#(?:[0-9a-f]{3}){1,2}$/i;
    const accentColorIsValid = !accentColor || hexColorRegex.test(accentColor);
    const primaryColorIsValid = !primaryColor || hexColorRegex.test(primaryColor);
    const maxDaysSearchIsValid = !Number.isNaN(Number(maxDaysSearch));

    if (!maxDaysSearchIsValid) {
      console.error('max-days-search is not a number, the default (30) will be used');
    }

    if (!accentColorIsValid) {
      console.error('accent-color is invalid, the default (#EC008C) will be used');
    }

    if (!primaryColorIsValid) {
      console.error('primary-color is invalid, the default (#002674) will be used');
    }

    this.config = {
      // prefill
      departureDate,
      destination,
      origin,
      passengers,
      returnDate,
      // URL
      forwardQuery,
      useUtmFor,
      utmParams: {
        campaign: utmCampaign,
        medium: utmMedium,
        source: utmSource,
        content: utmContent,
      },
      // behaviour
      searchTypes,
      hotelsUrl,
      hotelsResultsType,
      packagesUrl,
      Autocomplete: autocompleteType === 'typeahead' ? AutocompleteTypeahead : AutocompleteSelect,
      optInReturn: optInReturn === '' || optInReturn === 'true',
      showOpenTicket: showOpenTicket === '' || showOpenTicket === 'true',
      passengersDropdown: passengersDropdown === '' || passengersDropdown === 'true',
      openNewWindow: openNewWindow === '' || openNewWindow === 'true',
      maxDaysSearch: maxDaysSearchIsValid ? Number(maxDaysSearch) : 30,
      // visualization
      primaryColor,
      accentColor,
      widgetTitle,
      widgetLayout,
      brandingCopy,
      showErrors: showErrors === '' || showErrors === 'true',
      // general
      line,
      airline,
      transporter,
      sourceUrl,
      funnelUrl,
      useGtm: useGtm === '' || useGtm === 'true',
      displayType,
    };
  }

  initTest() {
    const { abtestProbability, abtestTarget } = this.props;
    const abtest = createUrl().queryParams['ab-test'];

    if (!abtestProbability) return;

    if (abtest === 'a' || abtest === 'b') {
      this.abtest = abtest;
    } else {
      const probability = abtestProbability ? Number(abtestProbability) : NaN;
      this.abtest = Number.isNaN(probability) ? getTest() : initTest(probability);
    }

    emitTest(this.abtest);

    // debugger;
    if (abtestTarget) {
      if (this.abtest === 'a') {
        $(abtestTarget).hide();
      } else {
        $(abtestTarget).show();
      }
    }
  }

  createTabsInfo() {
    const { searchTypes } = this.config;
    let types = searchTypes.split(',').map(type => type.trim());
    const [firstType] = types;

    if (firstType === 'all') {
      types = ['bus', 'packages', 'hotel'];
    }

    return types.reduce((accumulator, currentType) => {
      switch (currentType) {
        case 'bus':
          accumulator.push({
            title: 'Viajes',
            icon: 'travel',
            component: <Search config={this.config} />,
          });
          break;
        case 'packages':
          accumulator.push({
            title: 'Paquetes',
            icon: 'packages',
            component: <PackagesSearch config={this.config} />,
          });
          break;
        case 'hotel':
          accumulator.push({
            title: 'Hotels',
            icon: 'hotels',
            component: <HotelSearch config={this.config} />,
          });
          break;
        default:
          return accumulator;
      }

      return accumulator;
    }, []);
  }

  render({ compactHeight }) {
    if (this.abtest !== 'a') return null;

    const { widgetLayout } = this.config;
    const { activeTab } = this.state;

    const tabsData = this.createTabsInfo();

    return (
      <div
        className={`reservamos-search ${widgetLayout} ${
          compactHeight === '' ? 'compact-height' : ''
        }`}
      >
        {tabsData.length > 1 && (
          <Tabs activeTab={activeTab} onChangeTab={newTab => this.setState({ activeTab: newTab })}>
            {tabsData.map(tab => (
              <Tab title={tab.title} icon={tab.icon}>
                {tab.component}
              </Tab>
            ))}
          </Tabs>
        )}
        {tabsData.length === 1 && tabsData[0].component}
      </div>
    );
  }
}

export default SearchHub;
